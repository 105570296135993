import React, { useEffect } from "react";
import CampaignDetail from "./components/CampaignDetail";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export default function Root(props) {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (props.name === "@froneus/evi-agent-detail") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <QueryClientProvider client={queryClient}>
      <CampaignDetail />
    </QueryClientProvider>
  );
}
