import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataset,
  ChartTypeRegistry,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

type CustomChartDataset<T extends keyof ChartTypeRegistry = "doughnut"> =
  ChartDataset<T> & {
    porcent?: number[];
  };

export const ChartDoughnut = ({ data, colores, vistaAvanzada }) => {
  const [dataChart, setDatachart] = useState<{
    labels: string[];
    datasets: CustomChartDataset[];
  }>({
    labels: [],
    datasets: [],
  });

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: vistaAvanzada ? "Todos los contactos" : "Contactos efectivos",
        padding: {
          bottom: 16,
        },
        font: {
          size: 16,
          weight: "bold" as "bold",
        },
      },
      legend: {
        display: true,
        position: "top" as const,
      },
      datalabels: {
        display: "auto",
        color: "#222",
        backgroundColor: "rgba(255,255,255,.5)",
        borderRadius: 3,
        formatter: function (value, context) {
          const porcent = (context.dataset as CustomChartDataset).porcent;
          return porcent ? `${porcent[context.dataIndex]}%` : "";
        },
      },
    },
  };

  useEffect(() => {
    const formattedData = {
      labels: data.map((d) => d.estado),
      datasets: [
        {
          data: data.map((d) => d.casos),
          porcent: data.map((d) => d.porcentaje),
          backgroundColor: colores,
          borderWidth: 1,
          borderColor: "#fff",
        } as CustomChartDataset,
      ],
    };
    setDatachart(formattedData);
  }, [data, colores]);

  return (
    <Doughnut data={dataChart} options={options} width={200} height={200} />
  );
};
