export const viewTypes = {
  vnHome: "HOME",
  vfVerCampanasActivasSalientes: "VER CAMPANAS ACTIVAS SALIENTES",
  vfVerCampanasActivasEntrantes: "VER CAMPANAS ACTIVAS ENTRANTES",
  vfVerCampanasFinalizadas: "VER CAMPANAS FINALIZADAS",

  vnConfiguracion: "CONFIGURACION",
  vfAbmVoicebots: "ABM VOICEBOTS",
  vfAbmCategorias: "ABM CATEGORIAS",
  vfAbmGrupos: "ABM GRUPOS",
  vfAbmLocucion: "ABM LOCUCION",
  vfAbmClientes: "ABM CLIENTES",
  vfAbmUsuarios: "ABM USUARIOS",
  vfAbmAsterisks: "ABM ASTERISKS",
  vfAbmProveedores: "ABM PROVEEDORES",
  vfAbmEntidades: "ABM ENTIDADES",
  vfAbmIntenciones: "ABM INTENCIONES",
  vfAbmBodyWs: "ABM BODY WS",
  vfVerConfiguracion: "VER CONFIGURACION",

  vnListadoAgente: "LISTADO_AGENTE",
  vfVerReportesAgente: "VER REPORTES AGENTE",
  vfIrDetalleAgente: "IR DETALLE AGENTE",
  vfDescargarReportesLisAgente: "DESCARGAR REPORTES AGENTE",
  vfVerListadoAgente: "VER LISTADO AGENTE",
  vfVerAltaAgente: "VER ALTA AGENTE",
  vfVerEdicionAgente: "VER EDICION AGENTE",
  vfVerTodosLosAgentes: "VER TODAS LOS AGENTES",
  vfVerDuracionAgente: "VER DURACION AGENTE",
  vfElimnarAgente: "ELIMINAR AGENTE",

  vnDetalleAgente: "DETALLE_AGENTE",
  vfDescargarReportesDetAgente: "DESCARGAR REPORTES AGENTE",
  vfIrDetalleLlamadoAgente: "IR DETALLE LLAMADO AGENTE",
  vfControlarAgente: "CONTROLAR AGENTE",
  vfVerDashboradAgente: "VER DASHBOARD AGENTE",
  vfVerDetalleAgente: "VER DETALLE AGENTE",
  vfMostrarAgente: "MOSTRAR AGENTE",
  vfMostrarAudioAgente: "MOSTRAR AUDIO AGENTE",
  vfMostrarReprocesamientoAgente: "MOSTRAR REPROCESAMIENTO AGENTE",
  vfFuncionPlayPauseStopAgente: "FUNCION PLAY, PAUSE, STOP AGENTE",
  vfVerSeteosAgente: "VER SETEOS AGENTE",
  vfVerColumnasAvanzadasAgente: "VER COLUMNAS AVANZADAS AGENTE",
  vfVistaGraficoAvanzadoAgente: "VISTA GRAFICO AVANZADO AGENTE",
  vfDescargarArchivoAgente: "DESCARGAR ARCHIVO AGENTE",

  vnDetalleLlamado: "DETALLE_LLAMADO",
  vfVerAudio: "VER AUDIO",
  vfVerDetalleLlamado: "VER DETALLE LLAMADO",

  vnMonitoring: "MONITORING",
  vfVerMonitoring: "VER MONITORING",
};
